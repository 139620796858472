import React from 'react'
import { Link, graphql } from 'gatsby'

import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Hero from '../components/Article/hero'
// import Tags from './components/Home/components/Blogs/tags'
import './article.less'

const getBreadcrum = (location, crumbLabel) => {
  return (
    <div className='breadcrum'>
      <Link className="breadcrum-item" to={location.origin}>Home</Link>
      {" / "}
      <Link className="breadcrum-item" to={`${location.origin}/articles`}>Articles</Link>
      {" / "}
      <span style={{marginLeft: 4}}>{crumbLabel}</span>
    </div>
  )
}

function ArticleTemplate ({data, location}) {
  const post = data.contentfulArticle
  const previous = data.previous
  const next = data.next

  console.log("location: ", location)


  return (
    <Layout location={location}>
      <Seo
        title={post.title}
        description={post.description}
        // image={`http:${post.heroImage.resize.src}`}
        type="Article"
        origin={location.origin}
        pathname={location.pathname}
      />
      <div className="blog-post">
        {/* <Breadcrumb location={location} crumbLabel={location.pathname} className='breadcrum'/> */}
        {getBreadcrum(location, post.title)}
        <Hero
          className="hero"
          image={post.heroImage?.gatsbyImageData}
          title={post.title}
          // content={post.description}
        />
        <div className="container">
          <span className="meta">
            <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
            {post.body?.childMarkdownRemark?.timeToRead} minute read
          </span>
          <div className="article">
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: post.body?.childMarkdownRemark?.html,
              }}
            />
            {/* <Tags tags={post.tags} /> */}
            {(previous || next) && (
              <nav>
                <ul className="articleNavigation">
                  {previous && (
                    <li>
                      <Link to={`/articles/${previous.slug}`} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li style={{textAlign: 'right'}}>
                      <Link to={`/articles/${next.slug}`} rel="next" >
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulArticle(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      tags
      description
    }
    previous: contentfulArticle(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulArticle(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
